
































import { Client, Practitioner, Site } from '@/models';
import { PropType, computed, defineComponent, onMounted, ref } from '@/plugins/composition';
import { useLoader } from '@/composables/useLoader';

export default defineComponent({
  name: 'PractitionerSite',
  props: {
    practitioner: {
      type: Object as PropType<Practitioner>,
      required: true,
    },
  },
  setup(props) {
    const { loading, withLoader } = useLoader();
    const clients = ref<Client[]>([]);
    const search = ref('');

    const sites = computed({
      get: () => props.practitioner.sites,
      set: (items) => updateSites(items),
    });

    const fetchClients = async () =>
      withLoader(async () => {
        console.log('here');
        clients.value = await Client.with('sites').getAll();
      });

    const updateSites = async (sites: Site[]) => {
      await props.practitioner.updateRaw({
        sites: sites.map((site: Site) => site.uuid),
      });
    };

    onMounted(() => fetchClients());

    return {
      loading,
      clients,
      sites,
      search,
    };
  },
});
