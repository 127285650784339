



























import BirthdayPicker from '@/components/inputs/BirthdayPicker.vue';
import GenderInput from '@/components/inputs/GenderInput.vue';
import { Practitioner } from '@/models';
import { PropType, defineComponent, onMounted, reactive, ref } from '@/plugins/composition';
import { mdiEyeOffOutline, mdiEyeOutline } from '@mdi/js';
import { required, rules } from '@/domains/practitioner/modules/assessments/composables/validations';
import { storage } from '@/main';
import { useFormValidation } from '@/composables/useFormValidation';
import { useLoader } from '@/composables/useLoader';

type Form = { resetValidation: () => void };

export default defineComponent({
  components: { BirthdayPicker, GenderInput },
  name: 'PasswordForm',
  props: {
    practitioner: {
      type: Object as PropType<Practitioner>,
    },
  },

  setup(props) {
    const data = reactive({
      password: '',
    });

    const { errors, mapErrors, valid } = useFormValidation(data);
    const { loading, withLoader } = useLoader();

    const resent = ref(false);
    const form = ref<Form | null>(null);

    const user = ref<Practitioner | null>(null);

    onMounted(async () => {
      user.value = await Practitioner.find(storage.auth.asPatient.data.uuid);
    });

    const isPasswordVisible = ref(false);
    const isCurrentPasswordVisible = ref(false);

    const submit = async () =>
      withLoader(async () => {
        const response = await user.value?.updateRaw(data).catch((error) => {
          mapErrors(error);
        });

        if (response) {
          data.password = '';
          isPasswordVisible.value = false;
          isCurrentPasswordVisible.value = false;
          form.value?.resetValidation();
        }
      });

    return {
      data,
      errors,
      rules,
      required,
      valid,
      submit,
      loading,
      resent,
      form,

      icons: {
        mdiEyeOffOutline,
        mdiEyeOutline,
      },
      isPasswordVisible,
      isCurrentPasswordVisible,
    };
  },
});
