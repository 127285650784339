

















import Component from 'vue-class-component';

import PractitionerPrimaryDetails from '../../components/PractitionerPrimaryDetails.vue';
import PractitionerSite from '../../components/PractitionerSite.vue';
import Vue from 'vue';
import { Practitioner } from '@/models';
import { ProfileTile } from '../../../shared';
import { activeTab } from './activeTab';

@Component({
  components: {
    ProfileTile,
    PractitionerPrimaryDetails,
    PractitionerSite,
  },
})
export default class UserProfile extends Vue {
  user: Practitioner | null = null;

  get uuid(): string {
    return this.$route.params.uuid;
  }

  get activeTab(): number {
    return activeTab.value;
  }

  mounted(): void {
    this.getUser();
  }

  async getUser(uuid: string = this.uuid): Promise<void> {
    const user = await Practitioner.find(uuid);
    this.user = user;
  }
}
