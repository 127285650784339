









































import PatientAddressForm from '../../patients/components/forms/PatientAddressForm.vue';
import PatientDetailsForm from '../../patients/components/forms/PatientDetailsForm.vue';
import PractitionerPasswordForm from '@/domains/practitioner/modules/practitioners/components/PractitionerPasswordForm.vue';
import { Practitioner } from '@/models';
import { PropType, computed, defineComponent, onMounted, ref } from '@/plugins/composition';
import { storage } from '@/main';

export default defineComponent({
  name: 'PractitionerPrimaryDetails',
  components: {
    PatientDetailsForm,
    PatientAddressForm,
    PractitionerPasswordForm,
  },
  props: {
    value: {
      type: Object as PropType<Practitioner>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const openPanel = ref([]);

    const user = ref<Practitioner | null>(null);

    onMounted(async () => {
      user.value = await Practitioner.find(storage.auth.asPatient.data.uuid);
    });

    const resetOpenPanel = () => {
      openPanel.value = [];
    };

    const practitioner = computed(() => {
      return props.value;
    });

    const showPasswordForm = computed(() => {
      return props.value.isAdmin || user.value?.uuid === props.value.uuid;
    });

    const submit = async () => {
      const updatedPractitioner = await props.value.update();
      emit('input', updatedPractitioner);
      resetOpenPanel();
    };
    return {
      openPanel,
      resetOpenPanel,
      submit,
      showPasswordForm,
      practitioner,
    };
  },
});
