import { render, staticRenderFns } from "./PractitionerSite.vue?vue&type=template&id=764e7ace&scoped=true&"
import script from "./PractitionerSite.vue?vue&type=script&lang=ts&"
export * from "./PractitionerSite.vue?vue&type=script&lang=ts&"
import style0 from "./PractitionerSite.vue?vue&type=style&index=0&id=764e7ace&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "764e7ace",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTreeview } from 'vuetify/lib/components/VTreeview';
installComponents(component, {VCard,VSheet,VTextField,VTreeview})
